body {
  background-color: #F8F9FA !important;
}

main {
  padding-top: 36px;
}

.cashier-work-place {
  font-size: 25px !important;
  padding: 0 60px;
}

.cashier-work-place .btn,
.cashier-work-place .form-control,
.cashier-work-place .dropdown-item {
  font-size: 25px !important;
}

.cashier-work-place .badge {
  font-size: 26px !important;
}

.reports .table tr:last-child {
    font-weight: bold;
}

.register-print {
    display: none;
}

@media print {
    body * {
        visibility: hidden;
        margin: 0;
        padding: 0;
    }

    .register-print,
    .register-print * {
        visibility: visible !important;
        font-size: 16px;
    }

    .register-print {
        display: block !important;
    }

    .reports,
    .reports * {
        visibility: visible !important;
        margin: 8px 12px;
        font-size: 12px;
    }

    .reports .table,
    .register-print {
        page-break-after: always;
    }
}
