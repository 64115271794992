.receipt {
    width: 100%;
    max-width: 8cm;
    border: 1px solid black;
    box-sizing: border-box;
    /*padding: 24px 8px;*/
    color: black;
    background-color: white;
    /*font-family: 'GothamPro', sans-serif;*/
    /*margin-bottom: 16px;*/
    font-size: 14px;
}

.receipt p {
    line-height: 1.2;
}

.receipt-header {
    text-align: center;
    padding-bottom: 16px;
}

.receipt-header,
.receipt-row {
    font-size: 10px;
    margin: 0;
}


.receipt-menu {
    display: none;
    position: absolute;
    background-color: white !important;
    top: 0;
    left: 0;
    padding-left: 15px;
}

.receipt-menu:hover {
    display: block;
}

.receipt:hover + .receipt-menu {
    display: block;
}

@page {
    size: auto;
    margin: 0 !important;
    padding: 0 !important;
}

@media print {
    body * {
        visibility: hidden;
        padding: 0 !important;
    }

    .receipt-printable,
    .receipt-printable * {
        visibility: visible !important;
    }

    .receipt-printable {
        border: none;
        margin-bottom: 0;
        padding-left: 1cm !important;
        width: 8cm;
        page-break-after: always;
    }
}
